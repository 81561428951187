$default-space-interpolation: cubic-bezier(0.16, 1, 0.3, 1);

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes space-from-down {
  0% {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes space-from-left {
  0% {
    transform: translateX(-50px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes space-from-right {
  0% {
    transform: translateX(50px);
  }

  to {
    transform: translateX(0);
  }
}

@mixin fade-in-from($dir) {
  .animate-fade-in-from-#{$dir} {
    animation: fade-in 0.3s ease-in forwards,
      space-from-#{$dir} 1s $default-space-interpolation forwards;
  }
}

@include fade-in-from('left');
@include fade-in-from('right');
@include fade-in-from('down');

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-tooltip/dist/react-tooltip.css';
@import '~@gerber/commons-front/src/animations.scss';

.slick {
  &-slider {
    width: 100%;
  }

  &-track {
    display: flex;
    flex-direction: row;
  }
}

.react-tooltip {
  max-width: min(80vw, 450px) !important;
  white-space: normal;
  z-index: 999;
}
